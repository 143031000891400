export default {
  language: 'Español',
  remedyCategories: {
    collectStormwater: 'Aguas pluviales',
    plantVegetation: 'Plantas',
    improvePublicSpace: 'Servicios',
    reduceWaterUse: 'Reducción del consumo de agua',
    restoreWaterBody: 'Masas de agua',
    improveTransit: 'Tránsito',
  },
  interface: {
    languageTag: 'ES',
    next: 'Siguiente',
    learnMore: 'Más información',
    showMore: 'Mostrar más',
    showLess: 'Mostrar menos',
    backToStart: 'Volver al inicio',
    skipToTour: 'Ir al recorrido a pie',
    skipToSummary: 'Ir al resumen del proyecto',
    home: 'Inicio',
    returnToFieldKit: 'Volver al kit de campo',
    globeAlt: 'Ícono de un globo terráqueo',
    startFromBeginning: 'La historia',
    skipToWalkingTour: 'Recorrido a pie',
    twentyMinuteRead: 'lectura de 20 minutos',
    twentyFiveMinuteRead: 'lectura de 25 minutos',
    backToWatershed: 'Volver a la sección de cuencas hidrográficas',
    backToWaterTreatmentFacility: 'Volver a la parada 1',
    loaderAnimationAltText:
      'Dos aves acuáticas paradas en las aguas poco profundas, una de ellas agita brevemente las alas.',
    scrollToHistory: 'Desplácese para continuar con la historia',
    scrollInstruction: 'Desplácese para continuar',
    scrollToCredits: 'Desplácese para ver los créditos',
    settings: 'Ajustes',
    openTranscript: 'Abrir Transcripción',
    audioTranscript: 'Transcripción de audio',
    close: 'Cerrar',
    muteAudio: 'Silenciar el audio',
    parkOverview: 'Resumen del parque',
    stop: 'Parada',
  },
  title: {
    heading: 'Después de la lluvia',
    paragraphOne:
      'Demos un paseo para ver cómo la relación de una comunidad con el agua está ayudando a sanar viejas heridas.',
    paragraphTwoA: 'Descubre cómo',
    livingInfrastructure: 'la infraestructura ecológica',
    paragraphTwoB:
      'ayuda a corregir los efectos derivados de décadas de un mal uso en el Parque Magic Johnson. Aprenderás cómo las soluciones arquitectónicas, naturales y sociales contribuyen a sanar la tierra y la comunidad.',
    paragraphCompactA: 'Demos un paseo para ver cómo',
    paragraphCompactB:
      'ayuda a corregir los efectos derivados de décadas de un mal uso en el Parque Magic Johnson.',
    altText:
      'Hay un sendero renovado que llega hasta el Parque Magic Johnson. El sendero está rodeado por un lago cristalino, hierbas de humedal y árboles sanos.',
    illoAltText:
      'La hierba se agita, el agua chisporrotea y las aves extienden sus alas.',
    startFromBeginningAltText:
      'Trabajadores caminan junto a numerosas torres de perforación en un yacimiento petrolífero de Los Ángeles a principios de la década de 1920.',
    skipToWalkingTourAltText:
      'Un mapa señala seis lugares para un recorrido a pie por el parque Magic Johnson. Las paradas del recorrido son la estación de tratamiento de aguas pluviales, los humedales, el lago sur, el puente y el sendero del arroyo, la zona de chapoteo y el centro comunitario.',
  },
  history: {
    photoCredit:
      'Cortesía de la Sala de Historia de California, Biblioteca Estatal de California, Sacramento, California',
    willowbrook:
      'La comunidad no incorporada de Willowbrook, y el parque en su centro, necesitaba un cambio.',
    card: 'A principios de la década de 1920, en el lugar conocido actualmente como Parque Magic Johnson se almacenaban y distribuían productos derivados del petróleo.',
    rippleEffects:
      'Casi un siglo después, todavía se notan los efectos del uso indebido de la tierra.',
    willowbrookAlt:
      'Una vista aérea de la comunidad de Willowbrook en Los Ángeles. Las cuadras con casas y departamentos tienen pasto bien cuidado.',
    altText:
      'Trabajadores caminan junto a numerosas torres de perforación en un yacimiento petrolífero de Los Ángeles a principios de la década de 1920.',
    clickTheYears: 'Haga clic en los años para obtener más información',
  },
  intentToHeal: {
    investment:
      'En 2014, el condado invirtió 135 millones de dólares en un proyecto de renovación de varios años con el objetivo de ayudar a que el lugar se recuperara del mal uso en el pasado. Entre los planes estaban la revitalización del agua, nuevos espacios de reunión para la comunidad y la plantación de cientos de árboles.',
    impact:
      'El impacto del proyecto se extiende más allá de la superficie de 126 acres del parque.',
    visitorDogAlt:
      'Un visitante pasea a su perro junto al lago y el centro comunitario.',
    wideViewAlt:
      'Una vista panorámica del Parque Magic Johnson y uno de sus lagos.',
  },
  watershedContext: {
    location:
      'El Parque Magic Johnson se encuentra en la cuenca de 42.1 millas cuadradas del Arroyo de Compton, una subcuenca de la cuenca del Río de Los Ángeles.',
    before:
      'Antes de la renovación, las aguas pluviales y de escorrentía de la comunidad circundante desembocaban en el Arroyo de Compton. Esta agua terminaba en la Bahía de San Pedro, junto con toda la contaminación que había acumulado.',
    today: 'Actualmente, el agua se desvía al Parque Magic Johnson.',
    firstStop:
      'La primera parada de nuestro recorrido nos lleva por las aguas pluviales desviadas hacia el parque.',
    watershedAnimationAlt:
      'La lluvia cae sobre la cuenca del Río de Los Ángeles y desemboca en el Océano Pacífico.',
    comptonCreekBeforeAnimationAlt:
      'La lluvia cae sobre la cuenca del Arroyo de Compton y desemboca en el Río de Los Ángeles.',
    comptonCreekAfterAnimationAlt:
      'La lluvia cae sobre la cuenca del Arroyo de Compton, donde una parte importante de las aguas pluviales se capta en el Parque Magic Johnson.',
    watershedDiagramAlt:
      'Mapa de la cuenca del Río de Los Ángeles, que rodea los límites de la cuenca del Arroyo de Compton, el Arroyo de Compton, el Río de Los Ángeles, el centro de Los Ángeles y el Parque Magic Johnson.',
    comptonCreekDiagramAlt:
      'Mapa de la cuenca del Arroyo de Compton, que rodea los límites del Parque Magic Johnson, la cuenca de la comunidad de Willowbrook y el Arroyo de Compton.',
    altText:
      'Hay escombros y basura plástica esparcidos por el agua y las plantas del Arroyo de Compton.',
    impact:
      'El impacto del proyecto se extiende más allá de los límites del parque de 126 acres, a toda la cuenca del río Los Ángeles.',
    whatIsAWatershed: '...pero, ¿qué es exactamente una cuenca hidrográfica?',
    altTextRedux:
      'Una perspectiva aérea del parque Magic Johnson dentro del área más amplia de Los Ángeles muestra la estación de tratamiento de aguas pluviales, humedales, senderos, un lago, un centro comunitario y parques verdes, entre otras características.',
  },
  invitationToExplore: {
    takeWalk: 'Demos un paseo para explorar el parque.',
    altText:
      'Hay un sendero renovado que llega hasta el Parque Magic Johnson. El sendero está rodeado por un lago cristalino, hierbas de humedal y árboles sanos.',
  },
  overheadMap: {
    stops: [
      {
        title: 'Planta de tratamiento de agua',
        body: 'Descubre de dónde proviene el agua del Parque Magic Johnson, cómo se trata y cómo se reutiliza.',
      },
      {
        title: 'Humedales',
        body: 'Observe cómo los humedales ayudan a nutrir al Parque Magic Johnson. Aprenda cuáles son las especies de aves que tienen su hogar en el parque y explore la forma en que los microbios trabajan para filtrar el agua del lago. Asimismo, descubra por qué hay una pared de roca a lo largo de la orilla.',
      },
      {
        title: 'Lagos',
        body: 'Aprenda cómo los lagos retienen el agua y protegen el entorno, y cómo las rutas permeables ayudan a reponer las aguas subterráneas. También puede tomar un descanso en una de nuestras tranquilas bancas.',
      },
      {
        title: 'Puente y sendero del arroyo',
        body: 'Explore la multitud de árboles del Parque Magic Johnson, aprenda cuáles son las plantas nativas de California que están sanando el paisaje y observe cómo el arroyo y su puente facilitan el desplazamiento.',
      },
      {
        title: 'Chapoteadero',
        body: 'Descubre cómo el chapoteadero del parque brinda alegría a la comunidad.',
      },
      {
        title: 'Centro comunitario',
        body: 'Conoce cómo el centro sirve de espacio de reunión.',
      },
    ],
  },
  timeline: {
    athensTankFarm: {
      title: 'Depósito Atenas',
      text: 'El Depósito Atenas alberga oleoductos, tanques de almacenamiento y dos grandes reservas de petróleo crudo.',
      expanded:
        'La empresa General Petroleum Company adquiere el predio de 122 acres. Es explotado por entidades relacionadas con Mobil Oil Corporation hasta 1963. Entre sus actividades se encuentra la gestión de un "depósito de combustible" utilizado para el almacenamiento y la distribución de productos petrolíferos. Las instalaciones incluyen veintidós tanques de almacenamiento en superficie de 80,000 barriles, dos reservas revestidas de concreto y equipos para bombear y hacer circular los productos petrolíferos por toda la planta. Petróleo crudo, gasolina y diésel son algunos de los productos petrolíferos almacenados en el depósito. A lo largo de sus casi cuatro décadas en operación, se siguen construyendo viviendas residenciales en la zona que rodea el depósito. ExxonMobil Oil Corporation es el último propietario comercial del predio.',
      alt: 'Vista aérea del Depósito Atenas, rodeado de viviendas.',
    },
    exxonMobil: {
      title: 'Desalojo de Exxon Mobil',
      text: 'Exxon Mobil desocupa la zona.',
      expanded:
        'Entre 1962 y 1965, las instalaciones fueron retiradas del lugar y la propiedad fue vendida a una inmobiliaria privada llamada DeLay Land Company. No se realizan pruebas para detectar si el aire, las aguas subterráneas y el suelo están contaminados. Esta información no sería conocida por la comunidad sino hasta décadas después.',
      alt: 'Vista aérea de la zona del Depósito Atenas desalojada. El suelo está ennegrecido por la contaminación, pero sigue rodeado de conjuntos habitacionales.',
    },
    ujimaVillage: {
      title: 'Inauguración de Ujima Village',
      text: 'Se inaugura Ujima Village. "El pueblo" consistía en un complejo de 300 departamentos.',
      expanded:
        'Los diseños del proyecto original abarcan casi la totalidad del terreno e incluyen numerosas viviendas, un centro comercial de 10 acres, una guardería y una escuela primaria. El proyecto ha sido reconocido por el LA Times como el primero de su magnitud emprendido por y para la comunidad afroamericana. Sin embargo, los ambiciosos objetivos del proyecto se ven frustrados por problemas de presupuesto y el complejo de departamentos se convierte en la única obra del plan en materializarse. Este complejo de 300 viviendas fue diseñado por dos grupos de arquitectos afroamericanos: Kinsey, Meeds, and Williams y John D. Williams Associates. Se planificó en estrecha colaboración con una coalición de más de 50 organizaciones de la comunidad afroamericana. "Ujima" significa esfuerzo y responsabilidad colectivos en suajili, y la inauguración del complejo de departamentos se considera un triunfo de las familias afroamericanas de clase trabajadora. La zona circundante se convierte en un parque llamado "Espacio Recreativo de Willowbrook".',
      alt: 'Un trabajador empuja una carretilla entre las casas en construcción de Ujima Village.',
    },
    magicJohnson: {
      title: 'El parque es renombrado',
      text: 'El condado de Los Ángeles renombra el parque en honor a Earvin "Magic" Johnson.',
      expanded:
        "<em>\"El martes, el consejo de administración del condado de Los Ángeles aprobó por unanimidad cambiar el nombre del Espacio Recreativo de Willowbrook por el de Earvin (Magic) Johnson, calificándolo de 'héroe nacional', en honor a la estrella retirada de los Lakers de Los Ángeles.</em><br/><br/><em>El supervisor Kenneth Hahn, quien propuso el cambio, calificó a Johnson de 'héroe en la cancha de básquetbol' y de 'un gran ejemplo' para los jóvenes\".</em> - LA Times",
      alt: 'Recorte de periódico con el titular "Parque del condado recibe el nombre de Magic Johnson".',
    },
    hudSale: {
      title: 'Ujima Village es vendida',
      text: 'HUD vende el complejo de departamentos de Ujima Village a la Autoridad de Vivienda de Los Ángeles.',
      expanded:
        'El impago de hipotecas termina en una ejecución hipotecaria y HUD se adjudica la propiedad. A los pocos años, HUD vende la propiedad a la Autoridad de Vivienda de Los Ángeles por un 1 dólar. Antes de la venta del complejo de departamentos a la Autoridad de Vivienda, HUD realiza una investigación medioambiental y descubre que los suelos debajo de la propiedad contienen sustancias químicas derivadas de las operaciones del depósito de combustible. No obstante, HUD afirma que su presencia no supone un riesgo significativo para la salud.',
      alt: 'Un mapa muestra los límites del antiguo complejo petrolífero y se superpone con la contaminación ambiental.',
    },
    investigationResults: {
      title: 'Resultados de la investigación',
      text: 'Las investigaciones medioambientales revelan que el petróleo se filtró en el suelo durante los años de almacenamiento del petróleo en el lugar.',
      expanded:
        'Pruebas posteriores confirman de nuevo la presencia de sustancias químicas en el lugar. A los habitantes preocupados se les asegura que su agua potable es segura y que el suelo y las aguas subterráneas contaminados están demasiado por debajo de la superficie como para causar problemas de salud. Por su parte, el complejo de departamentos sufre décadas de mala gestión y deterioro. Cuando el condado intenta vender el complejo, las inmobiliarias hacen nuevos análisis del suelo y las aguas subterráneas que vuelven a demostrar que el lugar está contaminado con gasolina y petróleo.',
      alt: 'Un mapa muestra los límites del antiguo complejo petrolífero y se superpone con la contaminación ambiental.',
    },
    remediationBegins: {
      title: 'Restauración',
      text: 'Comienzan los esfuerzos para remediar la situación.',
      expanded:
        'La Comisión Regional de Control de Calidad del Agua de Los Ángeles (parte de la Agencia de Protección Ambiental de California) ordena a la Autoridad de Vivienda de Los Ángeles y a ExxonMobil sanear el lugar. Durante años, continúan las pruebas y las labores de limpieza, que incluyen la extracción del vapor del suelo, la remoción del suelo, pozos de control y otros métodos. En noviembre de 2008, los supervisores del condado de Los Ángeles votan a favor de cerrar el complejo y reubicar a los residentes.',
    },
    ujimaVillageCloses: {
      title: 'Cierre de Ujima Village',
      text: 'Ujima Village cierra.',
      expanded:
        'Aunque el condado sigue afirmando que la contaminación ambiental no es un problema de salud para los habitantes, califica el complejo como "degradado" debido al asbesto y a otros problemas de contaminación de los edificios. Ordena la reubicación de los habitantes y después clausura los departamentos. Más de 600 personas están desplazadas.',
      alt: 'Recorte de periódico con el titular "Se destruye una comunidad: La contaminación del suelo provoca el cierre de lo que fue una comunidad muy unida".',
    },
    allegationsFiled: {
      title: 'Se presenta una demanda',
      text: 'Los antiguos residentes de Ujima Village presentan una demanda.',
      expanded:
        'Más de 400 antiguos residentes de Ujima Village presentaron una demanda contra el condado, contra los desarrolladores del sitio y contra ExxonMobil, con el argumento de que intencionalmente no se les informó sobre los riesgos de la contaminación, la cual fue causa de  cáncer, asma y otros problemas de salud en la población, resultando en 38 decesos.',
      alt: 'Recorte de periódico con el titular "Cientos de personas demandan al condado de Los Ángeles por la contaminación de Ujima Village".',
    },
    ujimaVillageDemolished: {
      title: 'Demolición de Ujima Village',
      text: 'Fueron demolidas las unidades habitacionales de Ujima Village.',
      alt: 'Unas rejas con candado delimitan el complejo abandonado de Ujima Village.',
    },
    plansApproved: {
      title: 'Aprobación de los planes',
      text: 'Se aprueban los planes de renovación del parque.',
      expanded:
        'El Departamento de Parques y Recreación de Los Ángeles lleva a cabo una evaluación de las necesidades y determina que Willowbrook "carece de parques", lo que significa que hay una gran necesidad de áreas verdes. El departamento colabora con los miembros de la comunidad de Willowbrook en la elaboración de un proyecto maestro para la ampliación del parque (incluyendo el terreno de la antigua Ujima Village) y su mejora. El proyecto es aprobado por el consejo de administración del condado de Los Ángeles en febrero de 2016.',
      alt: 'La comunidad se reúne para debatir los planes de renovación del Parque Magic Johnson.',
    },
    renovationBegins: {
      title: 'Comienza la renovación',
      text: 'Da comienzo la extensa renovación del Parque Magic Johnson.',
      alt: 'Un mapa del Parque Magic Johnson renovado.',
    },
    parkReopens: {
      title: 'Reapertura del parque',
      text: 'El parque reabre.',
      expanded:
        'La primera fase de la renovación del parque incluyó plantar 300 árboles nuevos, 30,000 plantas, construir un nuevo sistema de aguas pluviales y un centro comunitario. El mismo Magic Johnson asistió a la ceremonia de inauguración. Este parque se celebra como uno de los espacios verdes más grandes del sur de Los Ángeles.',
      alt: 'Noticia de ABC7 con el titular "Visita preliminar del Parque Magic Johnson tras las obras de renovación valoradas en 70 millones de dólares".',
    },
    remediationContinues: {
      title: 'Continúa la restauración',
      text: 'Exxon sigue inspeccionando el lugar en busca de metano y otros contaminantes.',
    },
  },
  waterTreatmentFacility: {
    title: 'Planta de tratamiento de agua',
    waterCollected:
      'El agua captada de los desagües pluviales locales pasa por esta estación.',
    waterScrubbed:
      'Aquí, se eliminan las bacterias y otros contaminantes del agua antes de bombearla al lago.',
    waterTreated:
      'En el interior, el agua se trata con alumbre y ozono para eliminar las bacterias.',
    whatsGoingOn: '¿Qué pasa en el interior de este edificio?',
    waterSource: '¿De dónde viene el agua?',
    howDoesParkRedirect:
      '¿Cómo redirige el Parque Magic Johnson las aguas pluviales dentro de la cuenca de Compton Creek?',
    willowbrookWaterSource:
      'La comunidad de Willowbrook obtiene el agua de diversas fuentes locales y remotas. Parte de su agua proviene directamente de la cuenca central, una red de acuíferos que almacenan agua subterránea bajo Los Ángeles. El resto fluye desde el acueducto del Río Colorado y el Proyecto Estatal de Agua, recorriendo cientos de millas a través de canales, tuberías y depósitos antes de llegar a los habitantes, donde la gente la utiliza para bañarse, cocinar, lavar los trastes y más. Tras su uso, el agua sucia se envía a una planta de tratamiento de aguas residuales y, en última instancia, se vierte al océano.',
    pumpingStation:
      'Una tubería de desagüe con 7 pies de diámetro pasa por debajo de El Segundo Boulevard, en el extremo sur del Parque Magic Johnson. Esta tubería transporta las aguas de escorrentía (término utilizado para describir las aguas no pluviales, como el riego o el agua utilizada para lavar un coche en la entrada de una casa), así como las aguas pluviales, hasta la estación de bombeo del Parque Magic Johnson. Aquí, el agua se somete a una primera fase de limpieza para eliminar residuos grandes y basura, como bolsas de papas fritas y popotes de plástico, antes de dirigirse a la planta de tratamiento del parque.',
    altText:
      'La planta de tratamiento de aguas en las afueras del Parque Magic Johnson consiste en un pequeño edificio rectangular cerca de una zona con pasto.',
    illoAltText:
      'Gotas de lluvia caen de las nubes sobre la instalación de tratamiento de aguas y el parque cercano. Las aguas pluviales se bombean a través de tuberías a la instalación, se tratan y luego se bombean al lago. Una familia pasa junto a los trabajadores de mantenimiento que inspeccionan las instalaciones, mientras unos patos chapotean en los charcos.',
    hearFromHilton: 'Escuche a Hilton',
    hiltonPalm:
      'Hilton, residente en Willowbrook, nos cuenta cómo el parque Magic Johnson ha beneficiado a su familia.',
  },
  wetlands: {
    title: 'Humedales',
    afterLeaving:
      'Tras salir de la estación de tratamiento, el agua fluye hacia los nuevos humedales plantados en las orillas del lago sur. El agua nutre estos elementos naturales, y estos ayudan a limpiar aún más el agua.',
    biofiltration:
      'Las plantas de los humedales, como los juncos y los sauces, eliminan los contaminantes mediante un proceso natural llamado biofiltración.',
    whatAreWetlands: '¿Qué son los humedales?',
    filterChemicals:
      'Las plantas de los humedales ayudan a filtrar las sustancias químicas y contaminantes del agua del lago.',
    filterChemicalsAlt:
      'Primer plano de las hierbas de los humedales en el lago del Parque Magic Johnson.',
    howDoesBiofiltrationWork: '¿Cómo funciona la biofiltración?',
    whatMicrobes: '¿Qué microbios se esconden en este lago?',
    whatMicrobesAlt:
      'Una vista microscópica de los variados y dinámicos microbios que habitan en el lago del Parque Magic Johnson.',
    whatBirdsLive: '¿Qué aves viven en el Parque Magic Johnson?',
    whatBirdsLiveAlt: 'Una elegante garceta observa el lago desde la orilla.',
    wingedFriends:
      'Aprende qué especies de amigos alados viven aquí, en el Parque Magic Johnson.',
    wheresThisWater: '¿De dónde viene esta agua?',
    cleanWater:
      'El agua limpia fluye hacia el lago para seguir filtrándose en el humedal.',
    whyRockWall: '¿Por qué hay un muro de piedra?',
    slowIncomingWater:
      'El muro de piedra ayuda a frenar la entrada del agua para que las plantas del humedal puedan mantenerse bien asentadas.',
    slowIncomingWaterAlt:
      'Primer plano del muro de gaviones, una jaula de rocas parcialmente sumergida cerca de la orilla del lago.',
    altText:
      'Una vista panorámica del lago sur. Patos pasean por el lago y las plantas de los humedales. La planta de tratamiento de aguas apenas se ve al fondo.',
    illoAltText:
      'Una garceta y una tortuga descansan sobre el muro de piedra sumergido en el lago. Un martín pescador se posa en una rama que sobresale del agua. Patos nadan junto a las plantas de los humedales mecidas por el viento. En el fondo, la planta de tratamiento de aguas sigue en operación junto a la flora y la fauna.',
  },
  lakes: {
    title: 'Lagos',
    afterTheWater:
      'Luego de que el agua es limpiada por los humedales, desemboca en dos lagos de 15 acres.',
    holdStormwater:
      'La capacidad de los lagos para retener las aguas pluviales ayuda a mantener la comunidad a salvo de inundaciones.',
    reduceWaterUse:
      'También contribuyen a disminuir el consumo de agua del parque. Antes de la renovación, se utilizaba agua potable para regar el parque. Ahora, ese riego se complementa con aguas pluviales recicladas que provienen del lago.',
    howWaterUsed: '¿Cómo se usa el agua?',
    graywater:
      'Antes de la renovación, el parque utilizaba agua potable para regar el terreno, un recurso valioso que podía aprovecharse mejor. Ahora, las plantas y árboles del parque se riegan con aguas pluviales recicladas (también llamadas "aguas grises"), con lo que se conserva el agua potable y se ahorra dinero. Muchos habitantes de Los Ángeles pueden reciclar el agua en sus propios hogares captando agua doméstica sin detergente o agua de lluvia y reciclándola en sus jardines.',
    beforeTheRenovation:
      'Antes de la renovación, el riego del parque requería agua potable en un 100%.',
    oakTrees: 'Conoce la magia de los robles',
    muchToTeach:
      'Los robles del Parque Magic Johnson tienen mucho que enseñarnos sobre el uso responsable del agua.',
    muchToTeachAlt:
      'Un cielo despejado se asoma entre las ramas curvas y las hojas iluminadas por el sol de un roble.',
    whatIsThePathway: '¿De qué está hecho el sendero?',
    permeablePavingAlt: 'Primer plano de un camino arenoso y permeable.',
    permeablePaving:
      '¿Cómo ayudan los caminos permeables a mantener fresco el parque?',
    peacefulSpot: 'Explora este tranquilo lugar',
    takeABreak: 'Tómate un descanso y disfruta del paisaje.',
    takeABreakAlt:
      'Nubes blancas y tupidas flotan en el cielo azul del Parque Magic Johnson.',
    whatCanLakesHold: '¿Cuál es la capacidad de los lagos?',
    lakesCanHold:
      '¡Los lagos del parque pueden contener la asombrosa cantidad de 4.5 millones de galones de agua!',
    lakeCapacity:
      '¡Los dos lagos del Parque Magic Johnson abarcan un total de 15 acres y pueden contener la asombrosa cantidad de 4.5 millones de galones de agua! Esta agua procede de las corrientes de primera descarga (término utilizado para describir la escorrentía superficial inicial de la lluvia). Entre julio de 2021 y enero de 2022, los lagos captaron 4 millones de galones de agua de la cuenca comunitaria de 375 acres. Si no existieran estos lagos, el agua fluiría por las calles de Willowbrook, acumulando contaminantes y basura en su camino hacia el Arroyo de Compton, donde desembocaría directamente en el océano. Si los lagos se llenan, el agua aún puede desbordarse al Arroyo de Compton, pero sin toda la contaminación que habría retenido de no haber sido tratada en el parque.',
    altText:
      'La orilla de un lago en el Parque Magic Johnson. Los pastos de los humedales y la vegetación del parque cubren la orilla. A lo lejos se aprecia el renovado centro comunitario.',
    illoAltText:
      'Una nube deja caer lluvia a lo lejos. Los patos pasan junto a un paseador de perros. Un niño anda en bicicleta por el sendero permeable, mientras que otro salta en un charco cercano. Un jardinero utiliza las aguas pluviales recicladas del lago para regar plantas autóctonas, y una pareja disfruta del sereno paisaje de las aves que sobrevuelan el lago.',
  },
  streamBridgeAndTrail: {
    title: 'Puente y sendero del arroyo',
    gravityAndPumps:
      'Mediante la gravedad y bombas, el agua fluye entre los lagos, la planta de tratamiento y los humedales en un ciclo continuo. Este movimiento ayuda a evitar la proliferación de algas, lo que mantiene el agua sana y limpia.',
    soDoPeople:
      'A medida que el agua se desplaza por los lagos, también lo hace la gente.',
    bridgeConnects:
      'Un puente conecta un sendero de media milla que rodea los lagos. Tanto el puente como el sendero facilitan el acceso a la orilla a caminantes, corredores, familias y fauna salvaje. Además, los indicadores de distancia del sendero invitan a los habitantes a mantenerse sanos a través del movimiento, como el agua lo hace.',
    whyStreamHere: '¿Por qué hay un arroyo aquí?',
    streamHereBecause:
      'Este arroyo contribuye a mantener sana el agua del parque.',
    streamHereBecauseAlt: 'Primer plano del agua que corre por un arroyo.',
    whatPlantsLive: '¿Qué tipo de plantas habitan aquí?',
    californiaNatives:
      'Plantas autóctonas de California constituyen el 80% de las nuevas plantas que fueron trasplantadas en el parque.',
    californiaNativesAlt:
      'Primer plano de las hojas irradiadas de una artemisa autóctona de California.',
    exploreTheBridge: 'Explora el puente',
    stoppingPoint:
      'El puente es una parada para muchos visitantes del Parque Magic Johnson. Pueden deleitarse con el arroyo...',
    stoppingPointAlt:
      'El sol se pone detrás del puente del arroyo. Los visitantes del parque admiran el arroyo de abajo.',
    treesInThePark: '¿Qué árboles hay en el parque?',
    sourceOfShelter:
      'Los árboles del Parque Magic Johnson son fuente de refugio, sustento y diversión para muchas criaturas.',
    sourceOfShelterAlt:
      'Las hojas de un arce son iluminadas por el sol del atardecer.',
    altText:
      'Un arroyo fluye por un camino escalonado de rocas y atraviesa un verde paisaje. Un puente artificial cruza el arroyo.',
    illoAltText:
      'Mientras el agua reciclada fluye por el arroyo, los ciclistas pasan a toda velocidad por encima de un puente. Un niño trepa a un árbol inclinado cerca del arroyo, y dos corredores se estiran bajo la sombra de su follaje. Las flores autóctonas florecen al paso de los polinizadores.',
    hearFromErika: 'Escuche a Erika',
    erikaYanez:
      'Erika, residente en Willowbrook, nos cuenta cómo utiliza el parque Magic Johnson para hacer ejercicio y divertirse.',
  },
  splashPad: {
    title: 'Chapoteadero',
    socialTies:
      'Las infraestructuras ecológicas no solo contribuyen a la salud de los ecosistemas, sino también a los vínculos sociales.',
    parkHighlight:
      'Una de las atracciones más destacadas del parque es la zona de chapoteo, que emplea agua recirculada segura para jugar en ella.',
    refreshingPlace:
      'El chapoteadero es un lugar refrescante para que las familias se refresquen y se reúnan, con lo que se da alegría a la nueva generación y se refuerza la vitalidad y resiliencia de la comunidad a medida que se recupera.',
    whatAreStructures: '¿Qué son estas estructuras?',
    shadeStructures:
      'Las estructuras que dan sombra ayudan a reducir el efecto de isla de calor urbana.',
    shadeSails:
      'Estas estructuras se llaman velas de sombra. Ofrecen refugio contra el calor del sol, los rayos UV y la lluvia. Ayudan a que la zona de juegos del Parque Magic Johnson sea más segura y cómoda para los visitantes.',
    learnSplashPad: 'Más información sobre el chapoteadero',
    recirculateWater: '¿Cómo recircula el agua el chapoteadero?',
    coolRespite:
      'Cuando se activa, el chapoteadero rocía agua lo bastante limpia como para beberse, dando alegría a usuarios y transeúntes. El agua supone un fresco respiro en los calurosos días de verano y divierte a las familias que visitan el parque. Tras volver al suelo, el agua usada se drena en una fosa húmeda subterránea, donde se filtra y se trata a fondo. Luego se vuelve a enviar al chapoteadero hacia los usuarios que juegan con ella. Este proceso de recirculación ayuda a conservar el agua, al tiempo que refresca la comunidad.',
    takeBreak: 'Tómate un descanso',
    takeBreather: 'Tómate un descanso en una de las bancas del parque.',
    takeBreatherAlt:
      'Un sereno paisaje de un ave flotando en el lago, cerca del centro comunitario. El lago ondeante refleja las nubes y el cielo azul.',
    altText:
      'Junto a la zona de juegos hay un chapoteadero circular con aspersores integrados. Velas triangulares cuelgan sobre la mitad de la zona de juegos para proporcionar sombra.',
    illoAltText:
      'Hay una gran variedad de estructuras para que los niños jueguen: un tobogán, una estructura para trepar y un chapoteadero. Una madre empuja la carreola frente al chapoteadero, que rocía agua sobre los niños felices. Hay aves volando por encima de los árboles y de las velas triangulares instaladas que dan sombra a la zona de juegos.',
  },
  communityCenter: {
    title: 'Centro comunitario',
    newGatheringSpace:
      'Hay un centro comunitario de 20,000 pies cuadrados con vista a los lagos. Este nuevo espacio de reunión contribuye a unir a la comunidad.',
    sweepingMural:
      'Un amplio mural cubre la parte superior del centro. Fue elaborado por la artista de Los Ángeles Carla Jay Harris, quien comentó lo siguiente sobre la obra: "El concepto principal es un conjunto de guardianes encargados de supervisar y proteger un santuario comunitario".',
    renewSolidarity:
      'A medida que avanza la rehabilitación del terreno, el centro comunitario ofrece a los vecinos un lugar donde reencontrar juntos su sentido de solidaridad.',
    whatsThisArtwork: '¿Qué es esta obra de arte?',
    carlaJayHarris:
      'El mural que rodea el centro comunitario fue elaborado por Carla Jay Harris.',
    carlaJayHarrisAlt:
      'Carla Jay Harris, la pintora del mural dentro del centro comunitario.',
    centerProgramming: 'Programas del centro comunitario',
    ampleEvents: 'El centro cuenta con muchos eventos y programas.',
    rosterOfEvents:
      'El centro comunitario ofrece una completa y dinámica oferta de eventos y programas. Hay muchísimas maneras en que los habitantes pueden convivir, aprender, jugar, relajarse y formarse en el centro. Desde bocadillos para después de la escuela y ayuda con las tareas escolares, hasta conciertos, días para adultos mayores, jardinería, cursos de finanzas y una clínica médica móvil, el parque cuenta con numerosos recursos para una gran variedad de públicos. <a href="https://parks.lacounty.gov/earvin-magic-johnson-recreation-area/#" target="_blank">Haz clic aquí</a> para ver la lista completa de los programas.',
    hearFromWilly: 'Escucha la opinión de Willy',
    willyWilliams:
      'Willy, vecino de Willowbrook, nos cuenta cómo el Parque Magic Johnson ha beneficiado a la comunidad.',
    hearFromJohn: 'Escucha la opinión de John',
    johnDavis:
      'John, vecino de Willowbrook, nos cuenta cómo el Parque Magic Johnson ha beneficiado a la comunidad.',
    altText:
      'Un elaborado mural de Carla Jay Harris reviste el interior del centro. Hay mesas y un escenario, con rampa de acceso para personas con discapacidad.',
    illoAltText:
      'Además de una clase de yoga en el escenario, se llevan a cabo otras actividades. En las mesas redondas de abajo, los habitantes y los niños juegan, hacen la tarea juntos, comen, navegan por Internet y tocan la guitarra, entre otras actividades.',
  },
  conclusion: {
    cleanupEfforts:
      'Las labores de limpieza continúan en el Parque Magic Johnson; en la región sureste del lugar persiste una capa subterránea de vapores químicos y algunas especies de peces siguen siendo demasiado tóxicas para el consumo.',
    healthierPlace:
      'Pero la mejora de la calidad del agua, el aumento de la protección contra las inundaciones y la disponibilidad de lugares más seguros para reunirse han hecho de Willowbrook un lugar más sano al que llamar hogar.',
    bringingItAlive:
      'Además, la conexión de los elementos urbanos, los hábitats naturales y el tejido social del parque, es decir, su infraestructura ecológica, ha sido fundamental para darle vida.',
    tendingInfrastructure:
      'Cuidar esta infraestructura ecológica ayudará a que los cambios se consoliden y progresen, mientras prosiguen los esfuerzos por mejorar el parque.',
    altText:
      'Una vista panorámica de uno de los lagos, el centro comunitario, las plantas de los humedales y cuatro bancas de picnic.',
    illoAltText:
      'Flores y hierbas ondean por los senderos permeables. Árboles, artemisa y otras plantas rodean el lago. Una observadora de aves admira una garceta, y un visitante admira el lago biodiverso. La planta de tratamiento de aguas residuales vierte las aguas pluviales tratadas de nuevo al lago, y el mural del centro comunitario brilla en lo alto.',
  },
  projectSummary: {
    title: '¿Quiere reimaginar su propio espacio?',
    description:
      'Aprenda cómo el Kit de Campo puede ayudarle a usted y a su comunidad a imaginar posibilidades de resiliencia climática en su vecindario.',
    exploreUses: 'Explore los usos del kit de campo',
    requestAccess: 'Solicitar acceso a la herramienta de visión',
    altText:
      'Una calle ancha y asfaltada bordeada de coches, tendidos eléctricos y una vegetación mínima atraviesa las casas del centro de Los Ángeles. La niebla contaminante oculta parcialmente los edificios lejanos del centro de la ciudad.',
    exploreUsesAltText:
      'Un sendero de paseo serpentea por un frondoso y verde parque salpicado de árboles, así como una fuente de agua potable para los visitantes.',
    requestAccessAltText:
      'Una captura de pantalla de la herramienta de visión muestra varios iconos de remedios pegados a distintos barrios en un mapa de Los Ángeles, a modo de calcomanías.',
  },
  projectCredits: {
    altText:
      'Una toma aérea de patos nadando en las aguas azules de uno de los lagos del parque Magic Johnson.',
    livingInfraAlt:
      'Brazos, una tubería de aguas pluviales y una rama de árbol se entrelazan para apoyarse mutuamente.',
    landWaterAcknowledgement: 'Reconocimiento de las tierras y aguas',
    statement: [
      'Los creadores del material de campo sobre infraestructuras ecológicas reconocen que la zona geográfica representada en estos materiales (la zona regional integrada de gestión de aguas del condado de Los Ángeles) corresponde a tierras y aguas ancestrales no cedidas por los pueblos tongva, chumash y tataviam, los guardianes originales de estas tierras y aguas.',
      'Honramos a sus ancianos, tanto del pasado como del presente, y a los descendientes que son ciudadanos de estas tribus por su ejemplar respeto por el agua; honramos su continua relación y protección de uno de los entornos más bellos y diversos del mundo.',
      'Esperamos que esto contribuya a restaurar y fortalecer las relaciones y la concientización sobre las historias culturales y los contextos ecológicos.',
      'Para más información, consulta <a href="http://www.sacredplacesinstitute.org" target="_blank">Instituto de Lugares Sagrados de los Pueblos Indígenas</a> y <a href="https://tongva.land" target="_blank">Conservación Tongva</a>.',
    ],
    creditsTitle: 'Créditos',
    credits: [
      '"Después de la lluvia" fue elaborado con dedicación y solidaridad por ARLA y Spherical.',
      'Este proyecto fue posible gracias a un intenso proceso de colaboración, y nos gustaría agradecer a las personas a continuación por compartir su tiempo y talento:',
    ],
    feedbackTitle: 'Retroalimentación y asesoría:',
    feedbackCredits: [
      'Departamento de Obras Públicas del Condado de Los Ángeles',
      'Consejo de la Salud de las Cuencas Hidrográficas',
      'TreePeople',
      'Promesa Boyle Heights',
      'Pacoima Beautiful',
      'Red de Inclusión de Willowbrook',
      'Wendy Chan',
      'Barry Lehrman',
      'Mike Antos, Stantec',
      'Rosey Jencks',
    ],
    artMediaTitle: 'Arte y multimedia:',
    artMediaCredits: [
      'Fotografía y video de Rigo Santoyo, Junior Real, Kwasi Boyd-Bouldin, Chloé Savard, Irina Logra y Erik Shute',
      'Ilustración de Wyatt Hersey',
      'Animación de Claud Li',
      'Voz en off de Anthony Belcher, Sophia Guillen, Laneshia Miller, Alberto Pena, y Jonathan Pratt',
    ],
    additionalCredits: [
      'La renovación del Parque Magic Johnson es un proyecto del Condado de Los Ángeles, Paul Murdoch Architects, arquitectura paisajista y planeación y diseño urbano de AHBE | MIG, y Pacific Advanced Civil Engineering (PACE).',
      'Accelerate Resilience L.A. (ARLA) es un proyecto patrocinado por Rockefeller Philanthropy Advisors.',
    ],
    referencesTitle: 'REFERENCIAS COMPILADAS (MLA, 9ª edición)',
    references: [
      "Barragan, Bianca. <a href='https://la.curbed.com/2019/3/8/18256280/magic-johnson-park-willowbrook-renovations-begin' target='_blank' rel='noreferrer'>“$70M Renovation on Willowbrook’s Magic Johnson Park Getting Underway.”</a> Curbed Los Angeles, 8 Mar. 2019.",
      "California Water Boards, and CalEPA. <a href='https://www.waterboards.ca.gov/rwqcb4/water_issues/programs/remediation/Former_Athens_Tank_Farm/Fact%20Sheet%203_%20FINAL%20pdf.PDF' target='_blank' rel='noreferrer'>FACT SHEET #3: Former Athens Tank Farm</a>. California Water Boards &amp; CalEPA, May 2012.",
      "Dale, Mariana. <a href='https://laist.com/news/climate-environment/los-angeles-stormwater-rainwater-capture-treatment-reuse-willowbrook-earvin-magic-johnson-park' target='_blank' rel='noreferrer'>“Willowbrook Park, Fed By Rainwater, Is An Example of LA’s Stormwater Treatment Future.”</a> LAist, 10 Jan. 2023.",
      "<a href='https://www.youtube.com/watch?v=Th1oGGYkQ9s' target='_blank' rel='noreferrer'>Earvin “Magic” Johnson Park Urban Runoff Water Recycling</a>. Directed by PACE water, 2022.",
      'Hennessy-Fiske, Molly. “A Community Is Sundered: Tainted Soil Leads to Closure of What Was Once a Tight-Knit Complex.” Los Angeles Times, 17 Apr. 2009.',
      'Hennessy-Fiske, Molly. “County OKs Plan to Move Tenants.” Los Angeles Times, 15 Apr. 2009.',
      'Hennessy-Fiske, Molly. “Hundreds Sure L.A. County over Contamination.” Los Angeles Times, 13 Apr. 2010.',
      'Jones, Jack. “Opening of Ujima Village Set.” Los Angeles Times, 2 Nov. 1972.',
      'Jones, Jack. “Ujima Village to Open Saturday.” Los Angeles Times, 3 Nov. 1972.',
      'Kleinfelder West, Inc. Cultural Resource Evaluation of the Former Athens Tank Farm. addendum to the California Environmental Quality Act (CEQA) Guidelines Appendix G: Environmental Checklist Form, Section V Cultural Resources., ExxonMobil Environmental Services Company, 6 Aug. 2012.',
      'Knox, Charles E. “Community Support Spurs Black Project.” Los Angeles Times, 28 Feb. 1971.',
      'Kumbula, Tendayi J. “Ujima Village, Black Housing Project, Opens.” Los Angeles Times, 5 Nov. 1972.',
      "Miranda, Carolina A. “<a href='https://enewspaper.latimes.com/infinity/article_share.aspx?guid=38468632-4b88-43fe-aeb9-232ccaed50e5' target='_blank' rel='noreferrer'>It’s Not All Fun and Games: While Visitors Relax, Magic Johnson Park Does Crucial Work, Capturing and Recycling Water</a>.” Los Angeles Times, 23 May 2021.",
      "Miranda, Carolina A. <a href='https://www.latimes.com/entertainment-arts/story/2021-05-20/magic-johnson-park-water-capture-station' target='_blank' rel='noreferrer'>“What’s Hiding in Plain Sight at Magic Johnson Park? Maybe a Solution to Our Water Problem.”</a> Los Angeles Times, 20 May 2021.",
      'Morain, Dan, and Jill Stewart. “Foreclosure on 2 Privately Run Projects Sought.” Los Angeles Times, 26 Sept. 1989.',
      "Powell, Amy. <a href='https://abc7.com/lakers-magic-johnson-south-la-park/6794252/' target='_blank' rel='noreferrer'>“Sneak Peek at Magic Johnson Park after $70 Million Renovations Completed.”</a>ABC News, 5 Oct. 2020.",
      'Ridley-Thomas, Mark. Earvin “Magic” Johnson Recreation Area Revised Master Plan. County of Los Angeles Department of Parks and Recreation, Sept. 2019.',
      "Ridley-Thomas, Mark. <a href='https://ridley-thomas.lacounty.gov/index.php/magic-johnson-park/' target='_blank' rel='noreferrer'>“More Magic on Its Way to Magic Johnson Park.”</a> The Legacy of Mark Ridley-Thomas, Second District Supervisor 2008-2020.",
      'Rogan, Sean, and Los Angeles Housing Authority. Approve a Partial Settlement Agreement with HUD for Use of Ujima Village Replacement Reserves and Award a Contract to National Demolition Contractors for Demolition of Ujima Village. 19 June 2012.',
      'Second Supervisorial Districit. “New Project: Housing Authority of the County of Los Angeles Housing Management Division. Ujima Village Demolition Allocation: &lt;$1,000,000&gt;.” Los Angeles Times, 2011.',
      "Sentinel News Service. <a href='https://lasentinel.net/supervisor-ridley-thomas-and-partners-host-sneak-peek-of-70m-magic-johnson-park-renovation-and-community-drive-thru-tour.html' target='_blank' rel='noreferrer'>“Supervisor Ridley-Thomas and Partners Host Sneak Peek of $70m Magic Johnson Park Renovation and Community Drive-Thru Tour.”</a> Los Angeles Sentinel, 8 Oct. 2020.",
      "Sprowls, Robert, and Dave Schickling. <a href='http://www.gswater.com/WillowbrookCCR' target='_blank' rel='noreferrer'>Willowbrook Water System</a>. Golden State Water Company, 2021.",
      'State Water Resources Control Board. GeoTracker: HAZARDOUS MATERIAL INCIDENT REPORT FILED WITH OES. State Water Resources Control Board, 17 Nov. 2007.',
      "<a href='https://www.youtube.com/watch?v=XA6Eeh8aw9A.' target='_blank' rel='noreferrer'>The Live Oak | Julia Bogany</a>, Tongva Elder. Directed by Bridge Projects, 2020.",
      'Turpin, Dick. “Major Black Complex to Be Launched.” Los Angeles Times, 21 Feb. 1971.',
      'Unger, Sam. Former Athens Tank Farm Environmental Investigation &amp; Cleanup. Los Angeles Regional Water Quality Control Board Community Meeting.',
      'Unknown. “County Park Named for Magic Johnson.” Los Angeles Times, 20 Nov. 1991.',
    ],
  },
  settings: {
    title: 'Ajustes',
    highContrast: 'Alto contraste',
    reduceMotion: 'Reducir el movimiento',
    language: 'Idioma',
    languages: [
      { label: 'Inglés', value: 'english' },
      { label: 'Español', value: 'spanish' },
    ],
    fontSize:
      'Para ajustar la legibilidad de este interactivo, utilice los controles de tamaño de su navegador o de su computadora para aumentar o disminuir el tamaño de la fuente.',
    motionReduced:
      'Este interactivo respeta las preferencias de movimiento global actuales de su dispositivo minimizando el movimiento y la animación.',
    motionNotReduced:
      'Este interactivo respeta las preferencias de movimiento globales actuales de su dispositivo; sin embargo, puede ajustar la configuración de su sistema para una mayor accesibilidad.',
  },
  transcripts: {
    johnDavis: {
      title: 'Entrevista con John Davis',
      text: '<p>0:01<br>Me llamo John Davis. Estoy muy encantado de que el parque se encuentra justo en el centro de la comunidad, que la gente de la comunidad que viven en la comunidad puedan ir, que no tienen que viajar a los otros parques, que son capaces de ir al parque, hacer paseos, tener actividades.</p><p>0:15<br>De hecho, yo, me gusta el parque. Celebramos el cumpleaños número 75 de mi madre en el centro recreativo del parque.</p><p>0:23<br>Así que cuando va al parque, el ambiente es diferente; se ve a más gente reunida. Se siente bien y se ven las sonrisas en sus caras y la energía porque tienen algo propio. Es simplemente el hecho de que tienen un parque en su comunidad.</p><p>0:38<br>Si la comunidad en general viene de Willowbrook (vienen y ven el parque Magic Johnson), se sorprenderán de todas las otras cosas que pasan en el parque, los paseos, las características, las cosas a las que juegan los niños. Simplemente se asombrarían.</p>',
    },
    willyWilliams: {
      title: 'Entrevista con Willy Williams',
      text: '<p>0:00<br>Lo que esa parte hace por mi comunidad es que aporta belleza donde antes había tragedia.</p><p>0:09<br>Ahora tengo 65 años, llevo 64 aquí. Me crié aquí. Me crié en Willowbrook. Yo estaba aquí cuando todo esto no era más que edificios viejos y cosas así.</p><p>0:22<br>Pero en lo que a mí respecta, eso es lo mejor que le pudieron haber hecho a Willowbrook, fue construir el parque Magic Johnson.</p><p>0:29<br>Willowbrook es mi hogar. Willowbrook es mi casa.</p>',
    },
    hiltonPalm: {
      title: 'Entrevista con Hilton Palm',
      text: '<p>0:00<br>Me llamo Hilton. Hilton Palm.</p><p>0:02<br>Recuerdo cuando esto no era un parque, antes de que lo fuera. Llevo aquí mucho tiempo. Con todas las nuevas renovaciones, el parque para perros, la pista de atletismo de allí y todos los demás aparatos para hacer ejercicio, vengo más al parque. Me encanta.</p><p>0:15<br>Además de llevar a mi hijo al parque infantil, vamos a dar de comer a los patos, llevamos al perro (tengo un perro), llevamos al perro al parque para perros, y simplemente paseamos. Sabe, no he pescado, pero estoy pensando en comprarme unas cañas de pescar y poner mis cañas en el agua.</p><p>0:33<br>Me encanta. me ha afectado porque vengo más al parque.</p>',
    },
    erikaYanez: {
      title: 'Entrevista con Erika Yanez',
      text: '<p>0:01<br>Crecí en esta zona. De niña, mis padres nos enseñaron el parque y veníamos a dar de comer a los pájaros y a estar en esa pequeña zona del lago. </p><p>0:13<br>Y luego, de adulta, solía traer a mis hijos para Pascua y reuniones familiares, pero ahora lo uso más como una forma de ejercicio y me gustan las marcas en el suelo, lo que le dice cuánto ha caminado o cuánta distancia, por lo que es útil para mí para saber cuánto he logrado. </p><p>0:33<br>Parece que ahora está más limpio que antes. Los baños en su mayoría están siempre bien cuidados y además tenemos dos baños abiertos</p><p>0:45<br>Me parece un parque seguro. No me he encontrado con ningún reto ni ninguna agresión</p>',
    },
  },
}
